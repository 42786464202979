import React, { useContext } from "react"
import styled from "styled-components"
import MuiSelect from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

import EargoGenerationService from "../../services/eargo_generation_service"
import { GenerationsContext } from "../../providers/generations_provider"

const StyledMuiSelect = styled(MuiSelect)`
  && {
    min-width: 240px;
    padding: 0 10px;
    background-color: white;
    border-radius: 5px;
  }
`

const Wrapper = styled.div`
  display: inline-block;
  padding-top: 8px;
`

const SelectGeneration = () => {
  const { generation, generations, setGeneration } = useContext(
    GenerationsContext
  )

  const handleChange = (event) => {
    setGeneration(event.target.value)
  }

  return (
    <Wrapper>
      <StyledMuiSelect
        disableUnderline={true}
        value={generation}
        onChange={handleChange}
        label="Select Generation"
        align="left"
      >
        {generations.map((iteratingGeneration, index) => {
          return (
            <MenuItem key={index} value={iteratingGeneration}>
              {EargoGenerationService.getDisplayValue(iteratingGeneration)}
            </MenuItem>
          )
        })}
      </StyledMuiSelect>
    </Wrapper>
  )
}

export default SelectGeneration
