import { types, getEnv, getRoot } from "mobx-state-tree"
import { includes } from "lodash"

const EN = "en"
const AVAILABLE_LOCALES = [EN]

const LocaleStore = types
  .model("LocaleStore", {
    locale: types.optional(
      types.enumeration(AVAILABLE_LOCALES),
      AVAILABLE_LOCALES[0]
    ),
  })
  .views((self) => {
    return {
      get availableLocales() {
        return AVAILABLE_LOCALES
      },
      get isDefaultLocale() {
        return self.locale === AVAILABLE_LOCALES[0]
      },
      get isEnglishLocale() {
        return self.locale === EN
      },
    }
  })
  .actions((self) => {
    const { i18n, history } = getEnv(self)

    return {
      afterCreate() {
        self.initializeLocale()
      },
      initializeLocale() {
        const i18nLanguage = i18n.language.split("-")[0]
        self.setLocale(
          includes(AVAILABLE_LOCALES, i18nLanguage)
            ? i18nLanguage
            : AVAILABLE_LOCALES[0]
        )
      },
      setLocale(value) {
        self.locale = value
        i18n.changeLanguage(value)
      },
      toggleLocale() {
        const { translatePathname } = getRoot(self).routesStore
        const newLocale = EN

        history.push(translatePathname(history.location.pathname, newLocale))
      },
    }
  })

export default LocaleStore
