import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Step, Button, Grid } from "@material-ui/core"
import { observer } from "mobx-react"
import {
  CheckboxStep,
  CheckboxConnector,
  StyledStepContent,
  StyledStepper,
  StyledStepLabel,
} from "./stepper/stepper"
import Tile from "./tile/tile"
import { NotificationBell } from "images/svg"
import { useJournalStore, useUiStore } from "hooks/stores"
import Loading from "components/loading/loading"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const Wrapper = styled.div`
  display: flex;
  width: 95%;
  margin: 32px auto 0 auto;

  ${breakpoint("mobile")`
    flex-direction: column;
    height: 75vh;
    flex-direction: column;
  `}

  ${breakpoint("tablet")`
    flex-direction: column;
    height: 75vh;
    flex-direction: column;
  `}

  ${breakpoint("desktop")`
    ${({ isModalShown }) =>
      isModalShown
        ? `
        overflow-y: auto;
        overflow-x: hidden;
    `
        : `
        overflow-y: hidden;
        overflow-x: hidden;
    `}
    flex-direction: row;
  `}
`

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "710px",
    margin: "0 auto",
    maxHeight: "calc(100vh - 162px)",
    marginTop: "34px",
    overflowY: "auto",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}))

const Journey = () => {
  const { openNotification } = useUiStore()
  const {
    categoriesList,
    isLoaded,
    sendJourneyNotification,
  } = useJournalStore()
  const classes = useStyles()

  const onNotificationClick = async (id, label) => {
    await sendJourneyNotification(id, null)
    openNotification(`notifying user about ${label}`, "success")
  }

  if (!isLoaded) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    )
  }
  return (
    <div className={classes.root}>
      <StyledStepper orientation="vertical" connector={<CheckboxConnector />}>
        {categoriesList.map(
          ({ id, name, isCompleted, topicsList }, index) => (
            <Step key={id} active={true} completed={isCompleted}>
              <StyledStepLabel StepIconComponent={CheckboxStep}>
                {name}
                <Button onClick={() => onNotificationClick(id, name)}>
                  <NotificationBell />
                </Button>
              </StyledStepLabel>
              <StyledStepContent
                style={
                  index === categoriesList.length - 1
                    ? { borderLeft: "none" }
                    : undefined
                }
              >
                <Grid container spacing={2}>
                  {topicsList.map((props, index) => (
                    <Tile key={index} {...props} />
                  ))}
                </Grid>
              </StyledStepContent>
            </Step>
          )
        )}
      </StyledStepper>
    </div>
  )
}

export default observer(Journey)
