import React from "react"
import styled from "styled-components"
import colors from "styles/colors"
import { observer } from "mobx-react"
import DatePicker from "react-datepicker"
import { CalendarIcon } from "images/svg"
import { format } from "date-fns"

const TimepickerWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(55, 55, 55, 0.16);
  padding: 32px;

  &:focus {
    outline: none;
  }

  & > div > div > input {
    border-radius: 4px;
    height: 24px;
    border: 1px solid #003c7d;
    color: #003c7d;
    font-size: 16px;
    width: 190px;
    padding-left: 38px;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: white;
    border-bottom: none;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
    border-bottom: 1px solid #aeaeae;
    padding-bottom: 10px;
  }

  .react-datepicker__day-names {
    opacity: 0.6;
  }

  .react-datepicker__month {
    margin: 0 0.4em 0 0.4em;
    text-align: center;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
    background-color: ${colors.blue};
    color: #fff;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: #216ba5;
    color: #fff;
  }
`

const CalendarIconWrapper = styled.div`
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  left: 38px;
  z-index: 1;
  pointer-events: none;
`

const DateTimePicker = ({
  onDatepickerChange,
  startDate,
  endDate,
  disabled,
  minDate,
}) => {
  const onChange = (dates) => {
    onDatepickerChange(dates)
  }

  const datePickerValue =
    startDate || endDate
      ? `${format(new Date(startDate), "LLL dd")}${
          endDate ? ` - ${format(new Date(endDate), "LLL dd")}` : ""
        }`
      : null

  return (
    <TimepickerWrapper>
      <CalendarIconWrapper>
        <CalendarIcon />
      </CalendarIconWrapper>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        minDate={minDate}
        value={datePickerValue}
        placeholderText="logs are fetched LIVE"
        selectsRange
        isClearable
        shouldCloseOnSelect={false}
        disabled={disabled}
      />
    </TimepickerWrapper>
  )
}

export default observer(DateTimePicker)
