import React from "react"
import styled from "styled-components"
import colors from "styles/colors"
import { useConfigurationStore, useLogsStore, useUiStore } from "hooks/stores"
import { observer } from "mobx-react"

const ButtonDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff5e2b;
  margin-right: 8px;
  display: inline-block;
`
const PendingConfigurationButtonStyle = styled.button`
  font-weight: 400;
  width: 220px;
  height: 32px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.black};
  background-color: ${colors.white};
  border: none;
  float: right;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  margin-right: 24px;

  &:hover {
    cursor: pointer;
  }
`

const PendingConfigurationButton = () => {
  const { setHistoryDeviceConfiguration } = useConfigurationStore()
  const { openConfigurationModal } = useUiStore()

  const {
    lastLogWithStatus,
    setCurrentLogTitle,
    setCurrentLogDate,
  } = useLogsStore()

  const handlePendingButtonClick = ({ payload, message, createdAt }) => {
    setHistoryDeviceConfiguration(payload)
    setCurrentLogTitle(message)
    setCurrentLogDate(createdAt)
    openConfigurationModal()
  }

  return (
    <PendingConfigurationButtonStyle
      onClick={() => handlePendingButtonClick(lastLogWithStatus)}
    >
      <ButtonDot />
      {"Pending configuration"}
    </PendingConfigurationButtonStyle>
  )
}

export default observer(PendingConfigurationButton)
