import React, { useEffect } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const CallbackPage = () => {
  useEffect(() => {
    if (window.opener) {
      const params = new URL(window.location.href).searchParams
      const code = params.get("code")
      window.opener.postMessage(code)
    }
  }, [])

  return (
    <Wrapper>
      <div>Loading...</div>
    </Wrapper>
  )
}

export default CallbackPage
