import { types } from "mobx-state-tree"
import Charger from "./charger/charger"
import Hi from "./hi/hi"

const normalizeFirmwareVersion = (version) => {
  const parts = version.split(".");
  return Array.from(new Array(3))
    .map((_, i) => parts[i] || "0").join(".")
}

const FirmwareVersions = types
  .model("FirmwareVersions", {
    charger: types.optional(types.array(Charger), []),
    hi: types.optional(types.array(Hi), []),
  })
  .views((self) => {
    return {
      convertVersionToDisplayVersion(deviceType, version) {
        if (!version) {
          return "N/A"
        }
        const findVersion = self[deviceType].find(
          (item) => item.version === version
        )
        if (!findVersion) {
          return version
        }
        return normalizeFirmwareVersion(findVersion.displayVersion)
      },
    }
  })

export default FirmwareVersions
