import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import useAuth from "../../hooks/use_auth"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const LogoutPage = () => {
  const { handleLogout } = useAuth()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    handleLogout()
    history.push(`/oauth/login${location.search}`)
  }, [handleLogout, history, location.search])

  return (
    <Wrapper>
      <div>Loading...</div>
    </Wrapper>
  )
}

export default LogoutPage
