import { types } from "mobx-state-tree"
import HearingProfile from "../hearing_profiles/hearing_profiles"
import { sortBy, reverse } from "lodash"
import { toJS } from "mobx"
import Favourites from "../favourites/favourites"

const DeviceConfiguration = types
  .model("DeviceConfiguration", {
    id: types.maybeNull(types.number),
    userId: types.maybeNull(types.string),
    favouriteLeft: types.maybeNull(types.string),
    favouriteLeftSlot: types.maybeNull(types.number),
    favouriteLeftIndex: types.maybeNull(types.number),
    favouriteRight: types.maybeNull(types.string),
    favouriteRightSlot: types.maybeNull(types.number),
    favouriteRightIndex: types.maybeNull(types.number),
    hearingAssessmentLeftAudiogramIndex: types.maybeNull(types.number),
    hearingAssessmentRightAudiogramIndex: types.maybeNull(types.number),
    hearingAssessment: types.maybeNull(types.frozen()),
    isAsymmetricalHlChecked: types.maybeNull(types.boolean),
    hearingProfiles: types.optional(types.map(HearingProfile), {}),
    favourites: types.optional(types.array(Favourites), []),
    favouritesAppliedAt: types.maybeNull(types.number),
    haAppliedAt: types.maybeNull(types.number),
  })
  .views((self) => {
    return {
      get hearingProfilesList() {
        return sortBy(
          Array.from(self.hearingProfiles.values()),
          "position"
        ).slice(0, 4)
      },
      get configurationHistoriesList() {
        return reverse(
          sortBy(
            Array.from(self.configurationHistories.values()),
            ["createdAt"],
            ["asc"]
          )
        )
      },
      get plainDeviceConfiguration() {
        return {
          ...self,
          hearingProfiles: toJS(self.hearingProfilesList, {
            recurseEverything: true,
          }),
        }
      },
      get isHearingAssessmentResInAny() {
        return self.hearingProfilesList.some(
          (hearingProfile) => hearingProfile.isHearingAssessmentRes === true
        )
      },
      get hearingProfilesPrograms() {
        const programsArray = []
        self.hearingProfilesList.forEach((program) => {
          if (
            program.baseProgramLeft === "PROGRAM_A" &&
            program.baseProgramRight === "PROGRAM_A" &&
            program.isHearingAssessmentRes
          ) {
            programsArray.push("NORMAL")
          } else if (
            program.environmentalOffset === "NONE" &&
            !program.isHearingAssessmentRes
          ) {
            programsArray.push(
              program.baseProgramLeft || program.baseProgramRight
            )
          } else {
            programsArray.push(program.environmentalOffset)
          }
        })
        return programsArray
      },
      get newFavouriteRightSlot() {
        const favourites = toJS(self.favourites)
        if (self.favouriteRightIndex && favourites) {
          return self.hearingProfilesList.find((profile) => {
            return (
              profile.baseProgramLeft ===
                favourites[self.favouriteRightIndex]?.program &&
              profile.baseProgramRight ===
                favourites[self.favouriteRightIndex]?.program &&
              profile.environmentalOffset === "NONE" &&
              !profile.isHearingAssessmentRes
            )
          })?.position
        } else {
          return self.favouriteRightSlot
        }
      },
      get newFavouriteLeftSlot() {
        const favourites = toJS(self.favourites)
        if (self.favouriteLeftIndex && favourites) {
          return self.hearingProfilesList.find((profile) => {
            return (
              profile.baseProgramLeft ===
                favourites[self.favouriteLeftIndex]?.program &&
              profile.baseProgramRight ===
                favourites[self.favouriteLeftIndex]?.program &&
              profile.environmentalOffset === "NONE" &&
              !profile.isHearingAssessmentRes
            )
          })?.position
        } else {
          return self.favouriteLeftSlot
        }
      },
    }
  })
  .actions((self) => {
    return {
      setFavouriteLeft(value) {
        self.favouriteLeft = value
      },
      setFavouriteRight(value) {
        self.favouriteRight = value
      },
      setFavouriteLeftSlot(value) {
        self.favouriteLeftSlot = value
      },
      setFavouriteRightSlot(value) {
        self.favouriteRightSlot = value
      },
    }
  })

export default DeviceConfiguration
