import React, { useState, useEffect } from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { Grid, Paper } from "@material-ui/core"
import ActiveHours from "./active_hours/active_hours"
import { observer } from "mobx-react"
import ProgramsUsagePieChart from "./programs_usage_pie_chart/programs_usage_pie_chart"
import AverageVolumeBarChart from "./average_volume_bar_chart/average_volume_bar_chart"
import ProgramsUsageList from "./programs_usage_list/programs_usage_list"
import Loading from "components/loading/loading"
import { useSessionStore, useStatisticsStore } from "hooks/stores"
import MenuItem from "@material-ui/core/MenuItem"
import MuiSelect from "@material-ui/core/Select"
import { ProgrammedVCLegendIcon, UsedVCLegendIcon } from "images/svg"

const LeftWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  width: 50%;
  ${breakpoint("desktop")`
    width: 50%;
  `};
  ${breakpoint("tablet", "desktop")`
    margin-bottom: 16px;
    width: 100%;
  `};
  ${breakpoint("mobile", "tablet")`
    margin-bottom: 16px;
    width: 100%;
  `};
`

const InnerLeftWrapper = styled.div`
  ${breakpoint("mobile")`
    flex-direction: column;
  `};

  ${breakpoint("tablet")`
    flex-direction: column;
  `};

  flex: 1;

  &:first-child {
    max-height: 210px;
  }
`

const RightWrapper = styled.div`
  flex: 1.5;
  margin-left: 8px;
  height: 550px;
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  width: 95%;
  margin: 32px auto 0 auto;

  ${breakpoint("mobile")`
    flex-direction: column;
    height: 75vh;
    flex-direction: column;
  `}

  ${breakpoint("tablet")`
    flex-direction: column;
    height: 75vh;
    flex-direction: column;
  `}

  ${breakpoint("desktop")`
    ${({ isModalShown }) =>
      isModalShown
        ? `
        overflow-y: auto;
        overflow-x: hidden;
    `
        : `
        overflow-y: hidden;
        overflow-x: hidden;
    `}
    flex-direction: row;
  `}
`

const InnerLeftPaperFirst = styled(Paper)`
  && {
    position: relative;
    padding: 16px 0;
    height: inherit;
    margin-bottom: 16px;

    ${breakpoint("mobile", "tablet")`
       overflow-y: auto;
        overflow-x: hidden;
      `}
  }
`

const InnerLeftPaperSecond = styled(Paper)`
  height: 272px;
  padding-top: 42px;
`

const HeaderText = styled.div`
  position: relative;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  margin-left: 16px;
`

const ChartHeader = styled.div`
  position: relative;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  top: 48px;
  width: 95%;
  margin: 0 16px;
  display: inline-block;
`

const MiddleText = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 100%;
`

const ButtonsBar = styled.div`
  width: 92%;
  height: 40px;
  margin: 0 auto;
  padding: 16px 0 0 0;
`

const StyledMuiSelectDevice = styled(MuiSelect)`
  && {
    min-width: 120px;
    padding: 0 10px;
    background-color: white;
    border-radius: 5px;
  }
`

const StyledMuiSelectLogs = styled(MuiSelect)`
  && {
    min-width: 120px;
    padding: 0 10px;
    background-color: white;
    border-radius: 5px;
    margin-left: 20px;
  }
`

const LegendWrapper = styled.div`
  display: inline-block;
  float: right;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: none;
`

const ProgrammedVCWrapper = styled.div`
  display: inline-block;
  margin-right: 20px;
`

const UsedVCWrapper = styled.div`
  display: inline-block;
`

const Statistics = () => {
  const {
    isLoaded,
    setSelectedStatistics,
    setCurrentStatistics,
    selectedStatistics,
    currentStatistics,
    statisticsArray,
    statisticsList,
    setIsFullLogs,
  } = useStatisticsStore()

  const { eargoUserSystems, salesforceUser } = useSessionStore()

  useEffect(() => {
    setCurrentStatistics(statisticsList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsList])

  useEffect(() => {
    if (selectedStatistics) setDefaultValueDevice(selectedStatistics)
  }, [selectedStatistics])

  const [defaultValueDevice, setDefaultValueDevice] = useState(0)
  const [defaultValueLogs, setDefaultValueLogs] = useState(false)

  const handleChangeDevice = (event) => {
    setDefaultValueDevice(event.target.value)
    setSelectedStatistics(event.target.value)
  }

  const handleChangeLogs = (event) => {
    setDefaultValueLogs(event.target.value)
    setIsFullLogs(event.target.value)
  }

  if (!isLoaded) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    )
  }

  if (isLoaded && !salesforceUser.id) {
    return (
      <Wrapper>
        <MiddleText>
          <Paper>
            Salesforce user not found, to get statistics you need to pass url
            with salesforce id "?accountId="
          </Paper>
        </MiddleText>
      </Wrapper>
    )
  }

  if (!statisticsArray.length) {
    return (
      <>
        {eargoUserSystems?.length >= 2 && (
          <ButtonsBar>
            <StyledMuiSelectDevice
              disableUnderline
              labelId="notification-label"
              name="notification"
              onChange={handleChangeDevice}
              value={defaultValueDevice}
              label="Select User"
            >
              {eargoUserSystems.map((el, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {el.serialNumber}
                  </MenuItem>
                )
              })}
            </StyledMuiSelectDevice>
          </ButtonsBar>
        )}
        <Wrapper>
          <MiddleText>
            <Paper>There are no statistics to show.</Paper>
          </MiddleText>
        </Wrapper>
      </>
    )
  }

  return (
    <>
      {eargoUserSystems?.length && (
        <ButtonsBar>
          <StyledMuiSelectDevice
            disableUnderline
            labelId="notification-label"
            name="notification"
            onChange={handleChangeDevice}
            value={defaultValueDevice}
            label="Select Device"
          >
            {eargoUserSystems.map((el, index) => {
              return (
                <MenuItem key={index} value={index}>
                  {el.serialNumber}
                </MenuItem>
              )
            })}
          </StyledMuiSelectDevice>
          <StyledMuiSelectLogs
            disableUnderline
            labelId="notification-label"
            name="notification"
            onChange={handleChangeLogs}
            value={defaultValueLogs}
            label="Select logs"
          >
            <MenuItem value={false}>log since last reset</MenuItem>
            <MenuItem value={true}>all time logs average</MenuItem>
          </StyledMuiSelectLogs>
        </ButtonsBar>
      )}
      <Wrapper>
        <LeftWrapper>
          <InnerLeftWrapper>
            <InnerLeftPaperFirst>
              <ActiveHours currentStatistics={currentStatistics} />
            </InnerLeftPaperFirst>
          </InnerLeftWrapper>
          <InnerLeftWrapper>
            <ChartHeader>
              Average volume{" "}
              <LegendWrapper>
                <ProgrammedVCWrapper>
                  <UsedVCLegendIcon /> Used VC
                </ProgrammedVCWrapper>
                <UsedVCWrapper>
                  <ProgrammedVCLegendIcon /> Programmed VC
                </UsedVCWrapper>
              </LegendWrapper>
            </ChartHeader>
            <InnerLeftPaperSecond>
              <AverageVolumeBarChart />
            </InnerLeftPaperSecond>
          </InnerLeftWrapper>
        </LeftWrapper>
        <RightWrapper>
          <InnerLeftPaperFirst>
            <Grid container spacing={4}>
              <Grid item sm={5} xs={12}>
                <HeaderText>Program Usage</HeaderText>
                <ProgramsUsagePieChart />
              </Grid>
              <Grid item sm={7} xs={12}>
                <ProgramsUsageList />
              </Grid>
            </Grid>
          </InnerLeftPaperFirst>
        </RightWrapper>
      </Wrapper>
    </>
  )
}

export default observer(Statistics)
