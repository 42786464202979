class LocalStorageService {
  static getItem(key, fallbackValue = null) {
    const existing = localStorage.getItem(key)
    if (existing) {
      return JSON.parse(existing)
    }
    return fallbackValue
  }

  static setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  static removeItem(key) {
    localStorage.removeItem(key)
  }
}

export default LocalStorageService
