import { types } from "mobx-state-tree"
import Topics from "../topics/topics"

const Categories = types
  .model("Categories", {
    id: types.identifier,
    description: types.string,
    isCompleted: types.boolean,
    metadata: types.frozen(),
    name: types.string,
    createdAt: types.string,
    topics: types.optional(types.map(Topics), {}),
  })
  .views((self) => {
    return {
      get topicsList() {
        return Array.from(self.topics.values())
      },
    }
  })

export default Categories
