import React from "react"
import { configurationIconPicker } from "utils/helpers"
import styled from "styled-components"
import colors from "styles/colors"
import { observer } from "mobx-react"
import { useStatisticsStore } from "hooks/stores"
const StaticValue = styled.div`
  display: inline;
  position: absolute;
  top: 4px;
  font-size: 14px;
  line-height: 24px;
  color: ${({ side }) =>
    side === "none"
      ? `black`
      : side === "right"
      ? `${colors.purple}`
      : `${colors.numberBlue}`};
  right: ${({ side }) => (side === "right" ? `25%` : `10%`)};
`

const ListInnerItem = styled.div`
  display: block;
  height: 32px;
  position: relative;
`

const InnerListWrapper = styled.div`
  position: relative;
  padding-bottom: 12px;
  padding-top: 16px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    transform: translateX(8%);
    width: 84%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  }
`

const HeaderTextList = styled.div`
  display: inline-block;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  height: 28px;
  position: relative;
  bottom: 6px;
`

const AvgVolumeText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  bottom: 4px;
  left: 28px;
`

const ListWrapper = styled.div`
  margin-top: 16px;
  height: 518px;
  overflow-y: auto;
`

const IconWrapper = styled.div`
  display: inline-block;
  margin-left: 26px;
  width: 32px;
  height: 32px;
`

const ProgramsUsageList = () => {
  const { programUsageStatistics } = useStatisticsStore()
  const { usageList } = programUsageStatistics

  const returnName = (name) => {
    switch (name) {
      case "Program A":
        return "Preset 1"
      case "Program B":
        return "Preset 2"
      case "Program C":
        return "Preset 3"
      case "Program D":
        return "Preset 4"
      default:
        return name
    }
  }

  const usageListWithNamedPresets = usageList?.map((obj) => {
    obj.name = returnName(obj.name)
    return obj
  })

  if (!usageListWithNamedPresets?.length) return null

  return (
    <ListWrapper>
      {usageListWithNamedPresets.map(
        ({ name, rightUsage, leftUsage, averageVolume }, index) => (
          <InnerListWrapper key={index}>
            <ListInnerItem>
              <IconWrapper>
                {configurationIconPicker(
                  name.split(" ").join("_").toUpperCase()
                )}
              </IconWrapper>
              <HeaderTextList>{returnName(name)}</HeaderTextList>
              <StaticValue side={"right"}>R {rightUsage}%</StaticValue>
              <StaticValue side={"left"}>L {leftUsage}%</StaticValue>
            </ListInnerItem>
            <ListInnerItem>
              <AvgVolumeText>Avg bg volume</AvgVolumeText>
              <StaticValue side={"none"}>{averageVolume} Db</StaticValue>
            </ListInnerItem>
          </InnerListWrapper>
        )
      )}
    </ListWrapper>
  )
}

export default observer(ProgramsUsageList)
