import { types } from "mobx-state-tree"

const UiStore = types
  .model("UiStore", {
    showConfigurationModal: types.optional(types.boolean, false),
    showHearingAssessmentModal: types.optional(types.boolean, false),
    showLogsDrawer: types.optional(types.boolean, false),
    showDevicesDrawer: types.optional(types.boolean, false),
    showHearingAssessmentDialog: types.optional(types.boolean, false),
    activeNotification: types.optional(types.boolean, false),
    notificationsQueue: types.optional(
      types.array(
        types.model({
          message: types.string,
          severity: types.enumeration(["error", "warning", "info", "success"]),
        })
      ),
      []
    ),
  })
  .views((self) => {
    return {
      get notificationsQueueList() {
        return Array.from(self.notificationsQueue)
      },
    }
  })
  .actions((self) => {
    return {
      setActiveNotification(active) {
        self.activeNotification = active
      },
      openNotification(message, severity) {
        if (!message) return
        self.notificationsQueue.push({ message, severity })
        self.setActiveNotification(true)
      },
      shiftNotification() {
        self.notificationsQueue.shift()
        if (self.notificationsQueueList.length > 0)
          self.setActiveNotification(true)
      },
      closeNotification() {
        self.shiftNotification()
      },
      openConfigurationModal() {
        self.showConfigurationModal = true
      },
      closeConfigurationModal() {
        self.showConfigurationModal = false
      },
      openHearingAssessmentModal() {
        self.showHearingAssessmentModal = true
      },
      closeHearingAssessmentModal() {
        self.showHearingAssessmentModal = false
      },
      openHearingAssessmentDialog() {
        self.showHearingAssessmentDialog = true
      },
      closeHearingAssessmentDialog() {
        self.showHearingAssessmentDialog = false
      },
      switchLogsDrawer() {
        self.showLogsDrawer = !self.showLogsDrawer
      },
      switchDevicesDrawer() {
        self.showDevicesDrawer = !self.showDevicesDrawer
      },
      reset() {},
    }
  })

export default UiStore
