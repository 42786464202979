import { types } from "mobx-state-tree"

const NewHearingAssessment = types
  .model("NewHearingAssessment", {
    id: types.identifier,
    leftBisgaardIndex: types.maybeNull(types.number),
    rightBisgaardIndex: types.maybeNull(types.number),
    left500HzThreshold: types.maybeNull(types.number),
    left1000HzThreshold: types.maybeNull(types.number),
    left2000HzThreshold: types.maybeNull(types.number),
    left3000HzThreshold: types.maybeNull(types.number),
    left4000HzThreshold: types.maybeNull(types.number),
    right500HzThreshold: types.maybeNull(types.number),
    right1000HzThreshold: types.maybeNull(types.number),
    right2000HzThreshold: types.maybeNull(types.number),
    right3000HzThreshold: types.maybeNull(types.number),
    right4000HzThreshold: types.maybeNull(types.number),
    completeDate: types.maybeNull(types.string),
    active: types.maybeNull(types.boolean),
    failed: types.maybeNull(types.boolean),
    manuallyCreated: types.maybeNull(types.boolean),
    lastApplyDate: types.maybeNull(types.string),
    environmentalOffsets: types.frozen(),
    createdAt: types.maybeNull(types.Date),
  })
  .views((self) => {
    return {}
  })

export default NewHearingAssessment
