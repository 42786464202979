import React from "react"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import colors from "styles/colors"
import { configurationIconPicker } from "utils/helpers"
import { SelectCheckmark } from "images/svg"

const IconWrapper = styled.div`
  display: inline-block;
  margin: ${({ enabled }) => (enabled ? `14px 0 8px 6px` : `16px 0 16px 6px`)};
`

const InputValue = styled.input`
  position: absolute;
  width: 92px;
  height: 40px;
  border: 1px solid rgba(51, 51, 51, 0.22);
  box-sizing: border-box;
  border-radius: 4px;
  color: ${colors.black};
  right: ${({ side }) => (side === "right" ? `130px` : `20px`)};

  &:focus {
    outline: none;
  }
`

const EnvironmentalSelectsWrapper = styled.div`
  height: 60px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  cursor: pointer;
`

const TextWrapper = styled.div`
  display: inline-block;
  position: relative;
  bottom: ${({ enabled }) => (enabled ? `13px` : ` 7px`)};
  left: ${({ enabled }) => (enabled ? `7px` : `15px`)};
  font-size: 13px;
  font-weight: bold;
`

const StyledGrid = styled(Grid)`
  && {
    padding: 10px 10px 0 10px;
  }
`

const NumberInput = ({ value, edit, side, name, onChange, disabled }) => {
  const maxLengthCheck = (object) => {
    const {
      target: { value },
    } = object
    object.target.value = value > 75 ? 75 : value < 0 ? 0 : value
  }

  return (
    <>
      {edit && (
        <InputValue
          disabled={disabled}
          side={side}
          value={value}
          step={5}
          type="number"
          className={`${name} newConfigurationInput`}
          name={name}
          onChange={onChange}
          onInput={maxLengthCheck}
        />
      )}
    </>
  )
}

const EnvironmentalSelects = ({
  value,
  edit,
  onChange,
  environmentalOffsetsList,
}) => {
  const handleOnClick = (index) => {
    const data = {
      target: {
        name: `environmentalOffsets`,
        value: [value, index],
      },
    }
    onChange(data)
  }

  return (
    <StyledGrid container spacing={2}>
      {edit &&
        environmentalOffsetsList.map(({ offset, enabled }, index) => (
          <Grid item md={3} sm={6} xs={12}>
            <EnvironmentalSelectsWrapper onClick={() => handleOnClick(index)}>
              <IconWrapper enabled={enabled ? 1 : 0}>
                {enabled ? (
                  <SelectCheckmark />
                ) : (
                  configurationIconPicker(offset)
                )}
              </IconWrapper>
              <TextWrapper enabled={enabled ? 1 : 0}>{offset}</TextWrapper>
            </EnvironmentalSelectsWrapper>
          </Grid>
        ))}
    </StyledGrid>
  )
}

export { NumberInput, EnvironmentalSelects }
