import { types, getRoot } from "mobx-state-tree"
import {
  calculateActiveUsageHours,
  calculateAverageVolume,
  calculateRightUsageChart,
  calculateLeftUsageChart,
  calculateUsageList,
} from "utils/statistics_helper"
import { compareAsc, parseISO } from "date-fns"

const INITIAL = "initial"
const LOADING = "loading"
const LOADED = "loaded"
const NOT_FOUND_ERROR = "not_found"

const StatisticsStore = types
  .model("StatisticsStore", {
    state: types.optional(
      types.enumeration([INITIAL, LOADING, LOADED, NOT_FOUND_ERROR]),
      INITIAL
    ),
    selectedStatistics: types.optional(types.number, 0),
    currentStatistics: types.frozen({
      activeUsageHours: {},
      leftActiveUsageMinutes: {},
      rightActiveUsageMinutes: {},
      averageVolume: [],
      leftUsageChart: [],
      rightUsageChart: [],
      usageList: [],
    }),
    isFullLogs: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get isLoaded() {
        return self.state === LOADED
      },
      get isInitial() {
        return self.state === INITIAL
      },
      get isNotFoundError() {
        return self.state === NOT_FOUND_ERROR
      },
      get statisticsArray() {
        const { eargoUserSystems } = getRoot(self).sessionStore
        if (!self.isLoaded) return []

        return eargoUserSystems[self.selectedStatistics].statistics
          .slice()
          .sort(function (a, b) {
            a.createdAt ? (a = parseISO(a.createdAt)) : (a = 0)
            b.createdAt ? (b = parseISO(b.createdAt)) : (b = 0)
            return compareAsc(a, b)
          })
      },
      get statisticsList() {
        if (!self.statisticsArray.length) return null
        return {
          activeUsageHours: calculateActiveUsageHours(
            self.isFullLogs
              ? self.statisticsArray
              : [self.statisticsArray[self.statisticsArray.length - 1]]
          ),
          averageVolume: calculateAverageVolume(
            self.isFullLogs
              ? self.statisticsArray
              : [self.statisticsArray[self.statisticsArray.length - 1]]
          ),
          leftUsageChart: calculateLeftUsageChart(
            self.isFullLogs
              ? self.statisticsArray
              : [self.statisticsArray[self.statisticsArray.length - 1]]
          ),
          rightUsageChart: calculateRightUsageChart(
            self.isFullLogs
              ? self.statisticsArray
              : [self.statisticsArray[self.statisticsArray.length - 1]]
          ),
          usageList: calculateUsageList(
            self.isFullLogs
              ? self.statisticsArray
              : [self.statisticsArray[self.statisticsArray.length - 1]]
          ),
        }
      },
      get averageVolumeStatistics() {
        if (!self.statisticsArray.length) return null
        const { deviceConfiguration } = getRoot(self).configurationStore
        const { hearingProfilesPrograms } = deviceConfiguration
        const filtered = self.statisticsArray.map((log) => {
          log.averageVolume = log.averageVolume.filter((volume) => {
            return hearingProfilesPrograms.includes(
              volume.name.toUpperCase().split(" ").join("_")
            )
          })
          return log
        })

        return {
          averageVolume: calculateAverageVolume(filtered),
        }
      },
      get programUsageStatistics() {
        if (!self.statisticsArray.length) return null
        const { deviceConfiguration } = getRoot(self).configurationStore
        const { hearingProfilesPrograms } = deviceConfiguration
        const filtered = self.statisticsArray.map((log) => {
          log.usageList = log.usageList.filter((list) => {
            return hearingProfilesPrograms.includes(
              list.name.toUpperCase().split(" ").join("_")
            )
          })
          log.leftUsageChart = log.leftUsageChart.filter((list) => {
            return hearingProfilesPrograms.includes(
              list.name.toUpperCase().split(" ").join("_")
            )
          })
          log.rightUsageChart = log.rightUsageChart.filter((list) => {
            return hearingProfilesPrograms.includes(
              list.name.toUpperCase().split(" ").join("_")
            )
          })
          return log
        })

        return {
          programUsage: calculateUsageList(filtered),
          leftUsageChart: calculateLeftUsageChart(filtered),
          rightUsageChart: calculateRightUsageChart(filtered),
        }
      },
    }
  })
  .actions((self) => {
    return {
      startLoading() {
        self.state = LOADING
      },
      endLoading() {
        self.state = LOADED
      },
      setNotFoundError() {
        self.state = NOT_FOUND_ERROR
      },
      setCurrentStatistics(statistic) {
        if (!!statistic) self.currentStatistics = statistic
      },
      setSelectedStatistics(value) {
        self.selectedStatistics = value
      },
      setIsFullLogs(value) {
        self.isFullLogs = value
      },
      reset() {
        if (self.currentStatistics) {
          self.state = INITIAL
          self.currentStatistics = {
            activeUsageHours: {},
            leftActiveUsageMinutes: {},
            rightActiveUsageMinutes: {},
            averageVolume: [],
            leftUsageChart: [],
            rightUsageChart: [],
            usageList: [],
          }
        }
      },
    }
  })

export default StatisticsStore
