import { types } from "mobx-state-tree"
import EargoSystem from "../eargo_system/eargo_system"
import EargoGenerationService from "../../services/eargo_generation_service"

const User = types
  .model("User", {
    id: types.identifier,
    email: types.maybeNull(types.string),
    eusAddress: types.maybeNull(types.number),
    notificationTokens: types.maybeNull(types.frozen()),
    eargoSystems: types.optional(types.map(EargoSystem), {}),
    generation: types.optional(
      types.enumeration(Object.values(EargoGenerationService.GENERATIONS)),
      EargoGenerationService.GENERATIONS.EARGO_FIVE
    ),
    customer: types.frozen(),
  })
  .views((self) => {
    return {
      getEargoSystem(id) {
        return self.eargoSystems.get(id)
      },
      get eargoSystemsList() {
        return Array.from(self.eargoSystems.values())
      },
    }
  })
  .actions((self) => {
    return {
      addEargoDevices(devices) {
        let systems = {}

        devices.forEach((device) => {
          if (systems[device.systemSerialNumber] === undefined) {
            systems[device.systemSerialNumber] = []
          }
          systems[device.systemSerialNumber].push({ ...device })
        })

        Object.keys(systems).forEach((serialNumber) => {
          self.eargoSystems.set(serialNumber, {
            id: serialNumber,
            isActive: true, // ?
            serialNumber,
            userId: self.id,
          })

          const eargoSystem = self.getEargoSystem(serialNumber)

          systems[serialNumber].forEach((device) => {
            eargoSystem.addEargoDevice(device)
          })
        })
      },
    }
  })

export default User
