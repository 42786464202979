import { types } from "mobx-state-tree"

const HearingEvaluation = types
  .model("HearingEvaluation", {
    id: types.identifier,
    leftBisgaardIndex: types.maybe(types.number),
    rightBisgaardIndex: types.maybe(types.number),
    left500HzThreshold: types.maybe(types.number),
    left1000HzThreshold: types.maybe(types.number),
    left2000HzThreshold: types.maybe(types.number),
    left3000HzThreshold: types.maybe(types.number),
    left4000HzThreshold: types.maybe(types.number),
    right500HzThreshold: types.maybe(types.number),
    right1000HzThreshold: types.maybe(types.number),
    right2000HzThreshold: types.maybe(types.number),
    right3000HzThreshold: types.maybe(types.number),
    right4000HzThreshold: types.maybe(types.number),
    completeDate: types.maybeNull(types.string),
    rootGeneration: types.maybeNull(types.string),
    active: types.maybe(types.boolean),
    failed: types.maybe(types.boolean),
    manuallyCreated: types.optional(types.boolean, false),
    lastApplyDate: types.maybeNull(types.string),
    environmentalOffsets: types.frozen(),
    createdAt: types.maybeNull(types.string),
  })
  .views((self) => {
    return {}
  })

export default HearingEvaluation
