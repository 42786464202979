import React, {
  useCallback,
  useState,
  useMemo,
  createContext,
  useEffect,
} from "react"
import AuthService from "../services/auth_service"
import OAuthService from "../services/oauth_service"

export const AuthContext = createContext({})

const AuthProvider = ({ children, apiClient }) => {
  const [loggingInState, setLoggingInState] = useState({
    loading: false,
    error: null,
    success: false,
  })
  // this flag is a source of truth for the rest of the app
  const isLogged = useMemo(() => loggingInState.success, [
    loggingInState.success,
  ])

  // base handlers
  const handleLoginInit = useCallback(() => {
    setLoggingInState({ loading: true, success: false, error: null })
  }, [])

  const handleLoginSuccess = useCallback(
    (authData) => {
      // AuthService extends auth data, (refresh token missing in the refresh response)
      // this is why we need to use extended version of the authData
      const newData = AuthService.saveAuth(authData)
      apiClient.setAuthData(newData)
      setLoggingInState({ loading: false, success: true, error: null })
    },
    [apiClient]
  )

  const handleLoginError = useCallback(
    (error) => {
      apiClient.setAuthData(null)
      AuthService.removeAuth()
      setLoggingInState({ loading: false, success: false, error })
    },
    [apiClient]
  )

  // logout
  const handleLogout = useCallback(() => {
    apiClient.setAuthData(null)
    AuthService.removeAuth()
    setLoggingInState({ loading: false, success: false, error: null })
    // eslint-disable-next-line
  }, [])

  // login
  const handleLoginWithCode = useCallback(
    async (code) => {
      try {
        handleLoginInit()
        const data = await OAuthService.callback(code)
        await OAuthService.verifyAccessToken(data.accessToken)
        handleLoginSuccess(data)
      } catch (e) {
        console.error(e)
        handleLoginError(e)
      }
    },
    [handleLoginError, handleLoginInit, handleLoginSuccess]
  )

  // login from saved auth - initial page load
  useEffect(() => {
    // cannot log in if you're already logged
    if (isLogged) {
      return
    }

    const existingData = AuthService.getAuth()
    if (!existingData) {
      return
    }
    const verifyAndHandleExistingAuth = async () => {
      try {
        handleLoginInit()
        await OAuthService.verifyAccessToken(existingData.accessToken)
        handleLoginSuccess(existingData)
      } catch (e) {
        console.error(e)
        if (e.errorResponse?.status === 401) {
          return handleLoginError("invalid_session")
        }
        handleLoginError(e)
      }
    }

    verifyAndHandleExistingAuth()
    // we want to run this only once
    // eslint-disable-next-line
  }, [])

  // logout and refresh token listeners
  useEffect(() => {
    apiClient.on("logout", handleLogout)
    apiClient.on("token_refreshed", handleLoginSuccess)
    return () => {
      apiClient.off("logout")
      apiClient.off("token_refreshed")
    }
    // we want to run this only once
    // eslint-disable-next-line
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        loggingInState,
        handleLoginWithCode,
        handleLogout,
        handleLoginError,
        handleLoginInit,
        apiClient,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
