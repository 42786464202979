const colors = {
  black: "#000",
  white: "#fff",
  blue: "#003C7D",
  lightGrey: "#F0F0F0",
  grey: "#C0C0C0",
  checkboxGrey: "#DEDEDE",
  orange: "#FF5E2B",
  purple: "#A8145E",
  numberBlue: "#1666B6",
  green: "#43A047",
}

export { colors }

export default colors
