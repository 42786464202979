import React from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"

const TimeTextWrapper = styled.div`
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
`

const TimeWrapper = styled.div`
  align-items: center;
  text-align: center;
  margin-top: 24px;
`

const TimeValueWrapper = styled.div`
  font-size: 40px;
  line-height: 48px;
  align-items: center;
  text-align: center;
  display: inline-block;
`

const TimeStyleWrapper = styled.div`
  font-size: 18px;
  line-height: 48px;
  align-items: center;
  text-align: center;
  display: inline-block;
`

const ActiveHours = ({ currentStatistics }) => {
  if (!currentStatistics.activeUsageHours) return null

  const {
    activeUsageHours: {
      leftActiveUsageHours,
      rightActiveUsageHours,
      activeUsageHours,
    },
  } = currentStatistics

  // const mock = {
  //   leftActiveUsageHours: {
  //     timeSinceLastReset: {
  //       minutes: 15,
  //       hours: 5,
  //     },
  //     timeOutOfCharger: {
  //       minutes: 42,
  //       hours: 7,
  //     },
  //   },
  //   rightActiveUsageHours: {
  //     timeSinceLastReset: {
  //       minutes: 15,
  //       hours: 5,
  //     },
  //     timeOutOfCharger: {
  //       minutes: 42,
  //       hours: 7,
  //     },
  //   },
  // }
  if (activeUsageHours) {
    return (
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <TimeWrapper>
            <TimeTextWrapper>time since last reset</TimeTextWrapper>
            <TimeValueWrapper>
              {activeUsageHours?.timeSinceLastReset.hours}
            </TimeValueWrapper>
            <TimeStyleWrapper>h</TimeStyleWrapper>
            <TimeValueWrapper>
              {activeUsageHours?.timeSinceLastReset.minutes}
            </TimeValueWrapper>
            <TimeStyleWrapper>m</TimeStyleWrapper>
          </TimeWrapper>
        </Grid>
        <Grid item xs={6}>
          <TimeWrapper>
            <TimeTextWrapper>time out of charger</TimeTextWrapper>
            <TimeValueWrapper>
              {activeUsageHours?.timeOutOfCharger.hours}
            </TimeValueWrapper>
            <TimeStyleWrapper>h</TimeStyleWrapper>
            <TimeValueWrapper>
              {activeUsageHours?.timeOutOfCharger.minutes}
            </TimeValueWrapper>
            <TimeStyleWrapper>m</TimeStyleWrapper>
          </TimeWrapper>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <TimeWrapper>
            <TimeTextWrapper>time since last reset (L Hi)</TimeTextWrapper>
            <TimeValueWrapper>
              {leftActiveUsageHours?.timeSinceLastReset.hours}
            </TimeValueWrapper>
            <TimeStyleWrapper>h</TimeStyleWrapper>
            <TimeValueWrapper>
              {leftActiveUsageHours?.timeSinceLastReset.minutes}
            </TimeValueWrapper>
            <TimeStyleWrapper>m</TimeStyleWrapper>
          </TimeWrapper>
        </Grid>
        <Grid item xs={6}>
          <TimeWrapper>
            <TimeTextWrapper>time since last reset (L Hi)</TimeTextWrapper>
            <TimeValueWrapper>
              {leftActiveUsageHours?.timeOutOfCharger.hours}
            </TimeValueWrapper>
            <TimeStyleWrapper>h</TimeStyleWrapper>
            <TimeValueWrapper>
              {leftActiveUsageHours?.timeOutOfCharger.minutes}
            </TimeValueWrapper>
            <TimeStyleWrapper>m</TimeStyleWrapper>
          </TimeWrapper>
        </Grid>
        <Grid item xs={6}>
          <TimeWrapper>
            <TimeTextWrapper>time since last reset (R Hi)</TimeTextWrapper>
            <TimeValueWrapper>
              {rightActiveUsageHours?.timeSinceLastReset.hours}
            </TimeValueWrapper>
            <TimeStyleWrapper>h</TimeStyleWrapper>
            <TimeValueWrapper>
              {rightActiveUsageHours?.timeSinceLastReset.minutes}
            </TimeValueWrapper>
            <TimeStyleWrapper>m</TimeStyleWrapper>
          </TimeWrapper>
        </Grid>
        <Grid item xs={6}>
          <TimeWrapper>
            <TimeTextWrapper>time since last reset (R Hi)</TimeTextWrapper>
            <TimeValueWrapper>
              {rightActiveUsageHours?.timeOutOfCharger.hours}{" "}
            </TimeValueWrapper>
            <TimeStyleWrapper>h</TimeStyleWrapper>
            <TimeValueWrapper>
              {rightActiveUsageHours?.timeOutOfCharger.minutes}
            </TimeValueWrapper>
            <TimeStyleWrapper>m</TimeStyleWrapper>
          </TimeWrapper>
        </Grid>
      </Grid>
    )
  }
}

export default ActiveHours
