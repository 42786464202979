import React, { useContext } from "react"
import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import { Grid } from "@material-ui/core"
import * as Pages from "pages"
import { useRoutesStore } from "hooks/stores"
import styled from "styled-components"

import Loading from "../../../components/loading/loading"

import GenerationsProvider, {
  GenerationsContext,
} from "../../../providers/generations_provider"
import useAuth from "../../../hooks/use_auth"

const StyledContainer = styled(Grid)`
  && {
    //margin-top: 64px;
    min-height: calc(100vh - 42px);
    display: block;
  }
`

const ErrorWrapper = styled.div`
  padding: 1rem;
  background-color: rgba(255, 0, 0, 0.2);
  color: rgba(255, 0, 0, 1);
  margin: 1rem auto;
  border: 1px solid rgba(255, 0, 0, 1);
  border-radius: 0.25rem;
`

const AuthGuard = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.isLogged) {
    return (
      <Redirect
        to={{
          pathname: "/oauth/login",
          search: location.search,
          state: { referer: location.pathname },
        }}
        replace
      />
    )
  }
  return <>{children}</>
}

const AvailableGenerationsGuard = ({ children }) => {
  const generations = useContext(GenerationsContext)

  if (generations.error) {
    return <ErrorWrapper>{generations.error.toString()}</ErrorWrapper>
  }
  if (generations.loaded) {
    return <>{children}</>
  }
  return <Loading fitScreen />
}

const LayoutTemplate = () => {
  const routesStore = useRoutesStore()
  const location = useLocation()
  const background = location.state && location.state.background

  return (
    <StyledContainer container>
      <Grid item sm={12}>
        <Switch location={background || location}>
          <Route exact path={routesStore.root}>
            <AuthGuard>
              <GenerationsProvider>
                <AvailableGenerationsGuard>
                  <Pages.AuthorizedHomePage />
                </AvailableGenerationsGuard>
              </GenerationsProvider>
            </AuthGuard>
          </Route>
          <Route exact path={routesStore.logout}>
            <Pages.LogoutPage />
          </Route>
          <Route exact path={routesStore.login}>
            <Pages.LoginPage />
          </Route>
          <Route exact path={routesStore.callback}>
            <Pages.CallbackPage />
          </Route>
        </Switch>
        {background && (
          <Route path={routesStore.orderPage}>
            {/*<Pages.__SomeModal__ />*/}
          </Route>
        )}
      </Grid>
    </StyledContainer>
  )
}

const Desktop = () => {
  return (
    <>
      <LayoutTemplate />
    </>
  )
}

export default Desktop
