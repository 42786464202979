import { types } from "mobx-state-tree"

const MobileDevice = types
  .model("MobileDevice", {
    id: types.identifier,
    userId: types.string,
    phoneModel: types.string,
    operatingSystem: types.string,
    appVersion: types.string,
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
  })
  .views((self) => {
    return {}
  })

export default MobileDevice
