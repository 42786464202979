import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import colors from "styles/colors"
import Router from "router/router"
import { observer } from "mobx-react"

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    font-family: Inter;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${colors.white};
    font-style: normal;
  }
  
  ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      background: #fff;
      opacity: 0.7;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 10px;
  }
`

function App() {
  return (
    <>
      <ThemeProvider theme={{ colors }}>
        <Router />
      </ThemeProvider>
      <GlobalStyle />
    </>
  )
}

export default observer(App)
