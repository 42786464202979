import React, { useCallback, useEffect, useState } from "react"
import { Redirect, useLocation } from "react-router-dom"
import styled from "styled-components"

import colors from "../../styles/colors"
import useAuth from "../../hooks/use_auth"

import OAuthService from "../../services/oauth_service"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const LoginButton = styled.button`
  font-weight: 800;
  line-height: 24px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
  background-color: ${colors.orange};
  border: none;
  margin: 1rem auto;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  }

  &:disabled {
    background-color: ${colors.grey};
  }
`

const ErrorWrapper = styled.div`
  padding: 1rem;
  background-color: rgba(255, 0, 0, 0.2);
  color: rgba(255, 0, 0, 1);
  margin: 1rem auto;
  border: 1px solid rgba(255, 0, 0, 1);
  border-radius: 0.25rem;
`

const LoginPage = () => {
  const location = useLocation()
  const {
    isLogged,
    handleLoginWithCode,
    loggingInState,
    handleLoginInit,
    handleLoginError,
  } = useAuth()
  const [externalPopup, setExternalPopup] = useState(null)

  const handleMessage = useCallback(
    async (event) => {
      try {
        if (event.origin !== window.location.origin) {
          return
        }
        externalPopup.close()
        setExternalPopup(null)
        await handleLoginWithCode(event.data)
      } catch (e) {
        handleLoginError(e)
      }
    },
    [externalPopup, handleLoginError, handleLoginWithCode]
  )

  const handleLogin = useCallback(async () => {
    try {
      handleLoginInit()
      const url = await OAuthService.getAuthorizeUrl()
      const width = 400
      const height = 600
      const left = window.screenX + (window.outerWidth - width) / 2
      const top = window.screenY + (window.outerHeight - height) / 2.5
      const popup = window.open(
        url,
        `Salesforce Auth`,
        `width=${width},height=${height},left=${left},top=${top}`
      )
      setExternalPopup(popup)
    } catch (e) {
      handleLoginError(e)
    }
  }, [handleLoginError, handleLoginInit])

  useEffect(() => {
    if (!externalPopup) {
      return
    }
    window.addEventListener("message", handleMessage)
    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [externalPopup, handleMessage])

  function renderErrorIfExists() {
    if (!loggingInState.error) {
      return null
    }
    if (loggingInState.error === "invalid_session") {
      return (
        <ErrorWrapper>
          Log in expired or invalid. Please log in again.
        </ErrorWrapper>
      )
    }
    if (loggingInState.error.errorResponse?.status === 401) {
      return (
        <ErrorWrapper>
          Error: Your SF account is not authorized. Please contact SF support.
        </ErrorWrapper>
      )
    }
    return (
      <ErrorWrapper>Unexpected error. Please contact SF support</ErrorWrapper>
    )
  }

  if (isLogged) {
    return (
      <Redirect
        to={{
          pathname: "/",
          search: location.search,
          state: { referer: location.pathname },
        }}
        replace
      />
    )
  }

  return (
    <Wrapper>
      <LoginButton
        onClick={handleLogin}
        color="primary"
        disabled={loggingInState.loading}
      >
        Log in with SF
      </LoginButton>

      {renderErrorIfExists()}
    </Wrapper>
  )
}

export default LoginPage
