import React from "react"
import {
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Line,
  ComposedChart
} from "recharts"
import TooltipContent from "./tooltip_content"
import { useStatisticsStore } from "hooks/stores"
import styled from "styled-components"

const Wrapper = styled(ResponsiveContainer)`
  .uvcleft-line {
    transform: translateX(11.5px);
  }

  .uvcright-line {
    transform: translateX(-11.5px);
  }

  .recharts-line .recharts-curve {
    stroke: none;
  }
`

const AverageVolumeBarChart = () => {
  const { averageVolumeStatistics } = useStatisticsStore()
  const { averageVolume } = averageVolumeStatistics

  const returnName = (name) => {
    switch (name) {
      case "Program A":
        return "Preset 1"
      case "Program B":
        return "Preset 2"
      case "Program C":
        return "Preset 3"
      case "Program D":
        return "Preset 4"
      default:
        return name
    }
  }

  const averageVolumeWithNamedPresets = averageVolume.map((obj) => {
    obj.name = returnName(obj.name)
    return obj
  })

  if (!averageVolumeWithNamedPresets) return null

  const getPath = (x, y, width, height) =>
    `M ${x},${y} h ${width} v ${height - 3} h ${-width} Z 
     M ${x},${y + 3} H ${x - 6} V ${y} H ${x + 9} V ${y + 3} Z 
     M ${x},${y + height - 3} H ${x - 6} V ${y + height} H ${x + 9} V ${
      y + height - 3
    } Z`

  const TShapeBar = (props) => {
    const { fill, x, y, width, height } = props
    return (
      <path
        d={getPath(x, y, width, height)}
        height={height}
        width={width}
        x={x}
        y={y}
        stroke="none"
        fill={fill}
      />
    )
  }

  // const mock = [
  //   {
  //     name: "Music",
  //     uvcleft: [-3, 2],
  //     pvcleft: 2.22,
  //     uvcright: [-6, -3],
  //     pvcright: -6,
  //   },
  //   {
  //     name: "Program A",
  //     uvcleft: [-3, 4],
  //     pvcleft: 2.22,
  //     uvcright: [-5, 0.33],
  //     pvcright: -3,
  //   },
  //   {
  //     name: "Program C",
  //     uvcleft: [-1, 1],
  //     pvcleft: 2.22,
  //     uvcright: [-5, 0.33],
  //     pvcright: 0,
  //   },
  //   {
  //     name: "Program D",
  //     uvcleft: [4, 6],
  //     pvcleft: 5,
  //     uvcright: [-5, 0.33],
  //     pvcright: -2,
  //   },
  // ]

  return (
    <>
      <Wrapper height="100%" width="100%">
        <ComposedChart
          isAnimationActive={false}
          barGap={20}
          data={averageVolumeWithNamedPresets}
          margin={{
            top: 20,
            right: 30,
            left: -16,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[-6, 6]} tickCount={7} />
          <Tooltip content={<TooltipContent />} />
          <Bar
            isAnimationActive={false}
            shape={<TShapeBar />}
            barSize={3}
            dataKey="uvcright"
            fill={"#DD8AB4"}
          />
          <Bar
            isAnimationActive={false}
            shape={<TShapeBar />}
            barSize={3}
            dataKey="uvcleft"
            fill={"#8AB3DB"}
          />
          <Line
            isAnimationActive={false}
            activeDot={false}
            r={4}
            strokeWidth={2}
            dataKey="pvcleft"
            stroke={"#1666B6"}
            className={"uvcleft-line"}
          />
          <Line
            isAnimationActive={false}
            activeDot={false}
            r={4}
            strokeWidth={2}
            dataKey="pvcright"
            stroke={"#BB1669"}
            className={"uvcright-line"}
          />
        </ComposedChart>
      </Wrapper>
    </>
  )
}

export default AverageVolumeBarChart
