import React from "react"
import { Grid, Paper } from "@material-ui/core"
import styled from "styled-components"
import colors from "styles/colors"
import { useHearingEvaluationStore, useUiStore } from "../../../../hooks/stores"
import { observer } from "mobx-react"
import { NumberInput } from "./input/input"

const StyledPaper = styled(Paper)`
  && {
    background-color: ${colors.white};
    min-height: 335px;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
  }
`

const SettingLabel = styled.div`
  display: inline-block;
  margin: 8px 0 8px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  min-width: 140px;
`

const RowWrapper = styled.div`
  position: relative;
  display: block;
  height: 42px;
`

const HeaderWrapper = styled.div`
  display: block;
  height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  position: relative;
`

const HeaderText = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin: 16px 0 16px 10px;
  text-transform: uppercase;
`

const DuplicateText = styled.div`
  color: ${colors.orange};
  display: inline-block;
  position: relative;
  left: 108px;

  &:hover {
    cursor: pointer;
  }
`

const ThresholdWrapper = styled.div`
  width: 250px;
  margin: 0 auto;
`

const ThresholdTile = ({
  onChange,
  environmentalOffsetsList,
  handleDuplicateLeftClick,
}) => {
  const { newHearingAssessment } = useHearingEvaluationStore()
  const {
    left500HzThreshold,
    left1000HzThreshold,
    left2000HzThreshold,
    left3000HzThreshold,
    left4000HzThreshold,
    right500HzThreshold,
    right1000HzThreshold,
    right2000HzThreshold,
    right3000HzThreshold,
    right4000HzThreshold,
  } = newHearingAssessment
  const { showHearingAssessmentModal } = useUiStore()

  return (
    <StyledPaper>
      <form>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <ThresholdWrapper>
              <HeaderWrapper>
                <HeaderText>Left</HeaderText>
              </HeaderWrapper>
              <RowWrapper>
                <SettingLabel>500</SettingLabel>
                <NumberInput
                  side={"wide"}
                  value={left500HzThreshold}
                  name="left500HzThreshold-left"
                  edit={showHearingAssessmentModal}
                  onChange={onChange}
                />
              </RowWrapper>
              <RowWrapper>
                <SettingLabel>1k</SettingLabel>
                <NumberInput
                  side={"wide"}
                  value={left1000HzThreshold}
                  name="left1000HzThreshold-left"
                  edit={showHearingAssessmentModal}
                  onChange={onChange}
                />
              </RowWrapper>
              <RowWrapper>
                <SettingLabel>2k</SettingLabel>
                <NumberInput
                  side={"wide"}
                  value={left2000HzThreshold}
                  name="left2000HzThreshold-left"
                  edit={showHearingAssessmentModal}
                  onChange={onChange}
                />
              </RowWrapper>
              <RowWrapper>
                <SettingLabel>3k</SettingLabel>
                <NumberInput
                  side={"wide"}
                  value={left3000HzThreshold}
                  name="left3000HzThreshold-left"
                  edit={showHearingAssessmentModal}
                  onChange={onChange}
                />
              </RowWrapper>
              <RowWrapper>
                <SettingLabel>4k</SettingLabel>
                <NumberInput
                  side={"wide"}
                  value={left4000HzThreshold}
                  name="left4000HzThreshold-left"
                  edit={showHearingAssessmentModal}
                  onChange={onChange}
                />
              </RowWrapper>
            </ThresholdWrapper>
          </Grid>
          <Grid item md={6}>
            <ThresholdWrapper>
              <HeaderWrapper>
                <HeaderText>Right</HeaderText>
                <DuplicateText onClick={handleDuplicateLeftClick}>
                  Duplicate L
                </DuplicateText>
              </HeaderWrapper>
              <RowWrapper>
                <SettingLabel>500</SettingLabel>
                <NumberInput
                  side={"wide"}
                  value={right500HzThreshold}
                  name="right500HzThreshold-right"
                  edit={showHearingAssessmentModal}
                  onChange={onChange}
                />
              </RowWrapper>
              <RowWrapper>
                <SettingLabel>1k</SettingLabel>
                <NumberInput
                  side={"wide"}
                  value={right1000HzThreshold}
                  name="right1000HzThreshold-right"
                  edit={showHearingAssessmentModal}
                  onChange={onChange}
                />
              </RowWrapper>
              <RowWrapper>
                <SettingLabel>2k</SettingLabel>
                <NumberInput
                  side={"wide"}
                  value={right2000HzThreshold}
                  name="right2000HzThreshold-right"
                  edit={showHearingAssessmentModal}
                  onChange={onChange}
                />
              </RowWrapper>
              <RowWrapper>
                <SettingLabel>3k</SettingLabel>
                <NumberInput
                  side={"wide"}
                  value={right3000HzThreshold}
                  name="right3000HzThreshold-right"
                  edit={showHearingAssessmentModal}
                  onChange={onChange}
                />
              </RowWrapper>
              <RowWrapper>
                <SettingLabel>4k</SettingLabel>
                <NumberInput
                  side={"wide"}
                  value={right4000HzThreshold}
                  name="right4000HzThreshold-right"
                  edit={showHearingAssessmentModal}
                  onChange={onChange}
                />
              </RowWrapper>
            </ThresholdWrapper>
          </Grid>
        </Grid>
      </form>
    </StyledPaper>
  )
}

export default observer(ThresholdTile)
