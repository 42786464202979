import React, { useCallback, useContext, useEffect } from "react"
import { observer } from "mobx-react"
import {
  useConfigurationStore,
  useMobileDevicesStore,
  useHearingEvaluationStore,
  useJournalStore,
  useLogsStore,
  useSessionStore,
  useSocketStore,
  useStatisticsStore,
} from "../../hooks/stores"

import HomePage from "./home_page"
import { GenerationsContext } from "../../providers/generations_provider"

const AuthorizedHomePage = () => {
  const {
    fetch: sessionStoreFetch,
    userId,
    isLoaded,
    canFetchOtherData,
    reset: sessionStoreReset,
  } = useSessionStore()
  const {
    fetchConfiguration,
    reset: resetConfiguration,
  } = useConfigurationStore()
  const { initializeSocket } = useSocketStore()
  const { fetchJournal } = useJournalStore()
  const { fetchLogs, reset: resetLogs, initSocketLogs } = useLogsStore()
  const { reset: resetStatistics } = useStatisticsStore()
  const { fetchMobileDevices, reset: resetDevices } = useMobileDevicesStore()
  const {
    fetchHearingEvaluation,
    reset: resetHearingEvaluation,
  } = useHearingEvaluationStore()
  const { generation: currentGeneration } = useContext(GenerationsContext)

  useEffect(() => {
    sessionStoreReset()
    resetLogs()
    resetConfiguration()
    resetHearingEvaluation()
    resetStatistics()
    resetDevices()
  }, [currentGeneration])

  // should be moved to separate store that initialize all
  useEffect(() => {
    sessionStoreFetch()
  }, [currentGeneration])

  const onChangingSelect = useCallback(async () => {
    if (canFetchOtherData) {
      initializeSocket()
      initSocketLogs()
      resetLogs()
      resetConfiguration()
      resetHearingEvaluation()
      resetStatistics()
      resetDevices()

      await fetchMobileDevices() // need to fetch Devices early
      await fetchConfiguration()
      await fetchHearingEvaluation()
      await fetchJournal()
      await fetchLogs()
    }
  }, [userId, canFetchOtherData, currentGeneration])

  useEffect(() => {
    if (isLoaded) {
      onChangingSelect()
    }
  }, [onChangingSelect, isLoaded])

  return <HomePage />
}

export default observer(AuthorizedHomePage)
