import React from "react"
import { Grid } from "@material-ui/core"
import { Pie, PieChart, Cell, Tooltip } from "recharts"
import { useStatisticsStore } from "hooks/stores"

const ProgramsUsagePieChart = () => {
  const { programUsageStatistics } = useStatisticsStore()
  const { leftUsageChart, rightUsageChart } = programUsageStatistics

  const returnName = (name) => {
    switch (name) {
      case "Program A":
        return "Preset 1"
      case "Program B":
        return "Preset 2"
      case "Program C":
        return "Preset 3"
      case "Program D":
        return "Preset 4"
      default:
        return name
    }
  }

  // const mockLeftUsageChart = [{name:"Program D",value: 25}, {name:"Program A",value: 25}, {name:"Program C",value: 25}, {name:"Music",value: 25}]
  // const mockRightUsageChart = [{name:"Program D",value: 25}, {name:"Program A",value: 25}, {name:"Program C",value: 25}, {name:"Music",value: 25}]

  const leftUsageChartWithNamedPresets = leftUsageChart?.map((obj) => {
    obj.name = returnName(obj.name)
    return obj
  })

  const rightUsageChartWithNamedPresets = rightUsageChart?.map((obj) => {
    obj.name = returnName(obj.name)
    return obj
  })

  if (!leftUsageChartWithNamedPresets || !rightUsageChartWithNamedPresets)
    return null

  const R_COLORS = ["#ED66A9", "#E62C89", "#BB1669"]
  const L_COLORS = ["#6DADED", "#378FE7", "#1666B6"]

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <PieChart width={124} height={124}>
          <text x={60} y={62} textAnchor="middle" dominantBaseline="middle">
            R
          </text>
          <Pie
            data={rightUsageChartWithNamedPresets}
            cx={56}
            cy={56}
            innerRadius={30}
            outerRadius={50}
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
          >
            {rightUsageChartWithNamedPresets.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={R_COLORS[index % R_COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </Grid>
      <Grid item xs={6}>
        <PieChart width={124} height={124}>
          <text x={60} y={62} textAnchor="middle" dominantBaseline="middle">
            L
          </text>
          <Pie
            data={leftUsageChartWithNamedPresets}
            cx={56}
            cy={56}
            innerRadius={30}
            outerRadius={50}
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
          >
            {leftUsageChartWithNamedPresets.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={L_COLORS[index % L_COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </Grid>
    </Grid>
  )
}

export default ProgramsUsagePieChart
