import { types } from "mobx-state-tree"

const Favourites = types
  .model("favourites", {
    program: types.maybeNull(types.string),
    isActive: types.maybeNull(types.boolean),
    isHearingAssessmentRes: types.maybeNull(types.boolean),
    soundTuningLeftVolume: types.maybeNull(types.number),
    soundTuningRightVolume: types.maybeNull(types.number),
    soundTuningLeftTreble: types.maybeNull(types.number),
    soundTuningRightTreble: types.maybeNull(types.number),
    soundTuningLeftBass: types.maybeNull(types.number),
    soundTuningRightBass: types.maybeNull(types.number),
    isLeftFeedbackEnabled: types.maybeNull(types.boolean),
    isRightFeedbackEnabled: types.maybeNull(types.boolean),
    feedbackCorrectionLeft: types.maybeNull(types.array(types.number)),
    feedbackCorrectionRight: types.maybeNull(types.array(types.number)),
    appliedAt: types.maybeNull(types.number),
  })
  .views((self) => {
    return {}
  })

export default Favourites
