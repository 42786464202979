import React from "react"
import { Grid, Checkbox, Button } from "@material-ui/core"
import colors from "styles/colors"
import styled from "styled-components"
import { observer } from "mobx-react"
import { NotificationBell } from "images/svg"
import { useJournalStore, useUiStore } from "hooks/stores"

const TileWrapper = styled.div`
  padding: 8px;
  border: 1px solid rgba(51, 51, 51, 0.3);
  border-radius: 2px;
  position: relative;
`

const TextWrapper = styled.div`
  display: inline-block;
  width: 104px;
  white-space: break-spaces;
  font-size: 14px;
`

const NotificationIconWrapper = styled.div`
  vertical-align: top;
  display: inline-block;
  margin-left: 10px;
`

const StyledGreenCheckbox = styled(Checkbox)`
  && {
    vertical-align: top;
    padding: 0 9px 9px 9px;
    color: ${colors.green};
    &.Mui-checked {
      color: ${colors.green};
    }
    &:hover {
      background-color: #fff;
      cursor: default;
    }
  }
`

const StyledButton = styled(Button)`
  && {
    width: 20px;
    min-width: 0;
    padding: 0;
  }
`

const Tile = ({ name, isCompleted, id }) => {
  const { openNotification } = useUiStore()
  const { sendJourneyNotification } = useJournalStore()

  const onNotificationClick = async (label) => {
    await sendJourneyNotification(null, id)
    openNotification(`notifying user about ${label}`, "success")
  }

  return (
    <Grid item md={4} xs={12}>
      <TileWrapper>
        <StyledGreenCheckbox checked={isCompleted} />
        <TextWrapper>{name}</TextWrapper>
        <NotificationIconWrapper>
          <StyledButton onClick={() => onNotificationClick(name)}>
            <NotificationBell />
          </StyledButton>
        </NotificationIconWrapper>
      </TileWrapper>
    </Grid>
  )
}

export default observer(Tile)
