import React from "react"
import styled from "styled-components"

const TooltipWrapper = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  line-height: 17px;
`

const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TooltipLabel = styled.div`
  color: ${({ color }) => `${color}`};
  padding-right: 10px;
`

const TooltipValue = styled.div`
  color: ${({ color }) => `${color}`};
  text-align: right;
`
const returnName = (data) => {
  switch (data.dataKey) {
    case "pvcleft":
      return "Programmed Volume (left)"
    case "pvcright":
      return "Programmed Volume (right)"
    case "uvcleft":
      return "Used Volume (left)"
    case "uvcright":
      return "Used Volume (right)"
    default:
      return
  }
}

const returnColor = (data) => {
  switch (data.dataKey) {
    case "pvcleft":
      return "#1666B6"
    case "pvcright":
      return "#BB1669"
    case "uvcleft":
      return "#8BB3DB"
    case "uvcright":
      return "#DD8BB4"
    default:
      return
  }
}

const TooltipContent = (props) => {
  if (!(props.payload && props.payload[0] && props.payload[0].payload))
    return null

  const filterFunction = (inputProps, name) => {
    return inputProps.payload.filter((data) => data.dataKey === name)[0]
  }
  const pvcleftLabel = returnName(filterFunction(props, "pvcleft"))
  const pvcleftValue = props.payload.filter(
    (data) => data.dataKey === "pvcleft"
  )[0].value
  const pvcleftColor = returnColor(filterFunction(props, "pvcleft"))

  const pvcrightLabel = returnName(filterFunction(props, "pvcright"))
  const pvcrightValue = props.payload.filter(
    (data) => data.dataKey === "pvcright"
  )[0].value
  const pvcrightColor = returnColor(filterFunction(props, "pvcright"))

  const uvcleftLabel = returnName(filterFunction(props, "uvcleft"))
  const uvcleftValue = props.payload.filter(
    (data) => data.dataKey === "uvcleft"
  )[0].value
  const uvcleftColor = returnColor(filterFunction(props, "uvcleft"))

  const uvcrightLabel = returnName(filterFunction(props, "uvcright"))
  const uvcrightValue = props.payload.filter(
    (data) => data.dataKey === "uvcright"
  )[0].value
  const uvcrightColor = returnColor(filterFunction(props, "uvcright"))

  return (
    <TooltipWrapper>
      <ValueWrapper>
        <TooltipLabel color={pvcrightColor}>{pvcrightLabel}</TooltipLabel>
        <TooltipValue color={pvcrightColor}>{pvcrightValue}</TooltipValue>
      </ValueWrapper>
      <ValueWrapper>
        <TooltipLabel color={uvcrightColor}>{uvcrightLabel}</TooltipLabel>
        <TooltipValue
          color={uvcrightColor}
        >{`[${uvcrightValue[0]}, ${uvcrightValue[1]}]`}</TooltipValue>
      </ValueWrapper>
      <ValueWrapper>
        <TooltipLabel color={pvcleftColor}>{pvcleftLabel}</TooltipLabel>
        <TooltipValue color={pvcleftColor}>{pvcleftValue}</TooltipValue>
      </ValueWrapper>
      <ValueWrapper>
        <TooltipLabel color={uvcleftColor}>{uvcleftLabel}</TooltipLabel>
        <TooltipValue
          color={uvcleftColor}
        >{`[${uvcleftValue[0]}, ${uvcleftValue[1]}]`}</TooltipValue>
      </ValueWrapper>
    </TooltipWrapper>
  )
}

export default TooltipContent
