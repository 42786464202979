//configuration for mobile app below 1.2 version
import React from "react"
import { useUiStore } from "hooks/stores"
import { observer } from "mobx-react"
import { NumberInput, SelectNoiseInput } from "../../input/input"
import { SettingLabel, RowWrapper } from "../hearing_profile_tile_styles"

const HearingProfileTileInputsOldConfig = ({
  hearingProfile,
  index,
  onChange,
}) => {
  const {
    soundTuningLeftVolume,
    soundTuningRightVolume,
    soundTuningLeftTreble,
    soundTuningRightTreble,
    soundTuningLeftBass,
    soundTuningRightBass,
    noiseReduction,
  } = hearingProfile

  const { showConfigurationModal } = useUiStore()

  return (
    <>
      <RowWrapper>
        <SettingLabel>Volume</SettingLabel>
        <NumberInput
          side={"right"}
          value={soundTuningRightVolume}
          name={`soundTuningRightVolume-${index}`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
        <NumberInput
          side={"left"}
          value={soundTuningLeftVolume}
          name={`soundTuningLeftVolume-${index}`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
      <RowWrapper>
        <SettingLabel>Treble</SettingLabel>
        <NumberInput
          side={"right"}
          value={soundTuningRightTreble}
          name={`soundTuningRightTreble-${index}`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
        <NumberInput
          side={"left"}
          value={soundTuningLeftTreble}
          name={`soundTuningLeftTreble-${index}`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
      <RowWrapper>
        <SettingLabel>Bass</SettingLabel>
        <NumberInput
          side={"right"}
          value={soundTuningRightBass}
          name={`soundTuningRightBass-${index}`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
        <NumberInput
          side={"left"}
          value={soundTuningLeftBass}
          name={`soundTuningLeftBass-${index}`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
      <RowWrapper>
        <SettingLabel>Noise</SettingLabel>
        <SelectNoiseInput
          side={"wide"}
          value={noiseReduction}
          name={`noiseReduction-${index}`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
    </>
  )
}

export default observer(HearingProfileTileInputsOldConfig)
