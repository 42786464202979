import React from "react"
import styled from "styled-components"
import { Snackbar } from "@material-ui/core"
import { observer } from "mobx-react"
import { useUiStore } from "hooks/stores"
import Alert from "@material-ui/lab/Alert"

const StyledAlert = styled(Alert)`
  && {
    & .MuiAlert-icon {
      display: none;
    }
  }
`

function MaterialAlert(props) {
  return <StyledAlert elevation={6} variant="filled" {...props} />
}

const Notification = () => {
  const {
    activeNotification,
    notificationsQueueList,
    closeNotification,
  } = useUiStore()
  return (
    <>
      {!!notificationsQueueList.length && (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={activeNotification}
          onClose={closeNotification}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <MaterialAlert
            severity={
              notificationsQueueList[0]?.severity === undefined
                ? "success"
                : notificationsQueueList[0]?.severity
            }
          >
            {notificationsQueueList[0]?.message}
          </MaterialAlert>
        </Snackbar>
      )}
    </>
  )
}

export default observer(Notification)
