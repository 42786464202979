import { createTheme } from "@material-ui/core/styles"
import colors from "styles/colors"

export default createTheme({
  typography: {
    fontSize: 14,
    fontFamily: "Inter",
  },
  palette: {
    primary: {
      main: colors.black,
    },
  },
  breakpoints: {
    values: {
      sm: 768,
      md: 992,
      lg: 1200,
    },
  },
})
