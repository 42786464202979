import { types } from "mobx-state-tree"

const Topics = types
  .model("Topics", {
    id: types.identifier,
    description: types.string,
    isCompleted: types.boolean,
    metadata: types.frozen(),
    name: types.string,
  })
  .views((self) => {
    return {}
  })

export default Topics
