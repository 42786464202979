import { types } from "mobx-state-tree"

const EargoDevice = types
  .model("EargoDevice", {
    id: types.identifier,
    updatedAt: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    serialNumberAscii: types.maybeNull(types.string),
    isActive: types.maybeNull(types.boolean),
    deviceType: types.maybeNull(types.string),
    deviceOrigin: types.maybeNull(types.string),
    firmwareVersion: types.maybeNull(types.string),
    displayName: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    generation: types.maybeNull(types.string),
    systemSerialNumber: types.maybeNull(types.string),
  })
  .views((self) => {
    return {}
  })

export default EargoDevice
