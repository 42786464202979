import colors from "styles/colors"
import {
  Checkbox,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
} from "@material-ui/core"
import React from "react"
import styled from "styled-components"

const StyledStepCheckbox = styled(Checkbox)`
  && {
    position: relative;
    right: 70%;
    transform: translateX(50%);
    color: ${colors.green};
    &.Mui-checked {
      color: ${colors.green};
    }
    &:hover {
      background-color: #fff;
      cursor: default;
    }
  }
`

const CheckboxStep = (props) => {
  const { completed } = props

  return (
    <div>
      <StyledStepCheckbox checked={completed} />
    </div>
  )
}

const CheckboxConnector = styled(StepConnector)`
  && {
    margin-left: 10px;
    .MuiStepConnector-line {
      border-top-width: 3px;
      border-radius: 1px;
      border-left: 4px solid rgba(51, 51, 51, 0.17);
    }
  }
`

const StyledStepContent = styled(StepContent)`
  && {
    margin-top: 0;
    border-top-width: 3px;
    border-radius: 1px;
    border-left: 4px solid rgba(51, 51, 51, 0.17);
    margin-left: 10px;
  }
`

const StyledStepper = styled(Stepper)`
  && {
    padding: 24px 14px;
  }
`

const StyledStepLabel = styled(StepLabel)`
  && {
    .MuiStepLabel-label {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 14px;
    }
  }
`

export {
  CheckboxStep,
  CheckboxConnector,
  StyledStepContent,
  StyledStepper,
  StyledStepLabel,
}
