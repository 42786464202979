import ApiClient from "../utils/api_client"
import AppConfigService from "./app_config_service"

const config = AppConfigService.getConfig()

class OAuthService {
  static apiClient = new ApiClient()

  static async callback(code) {
    const { data } = await OAuthService.apiClient.post(`/v1/oauth/callback`, {
      code,
      redirect_uri: config.SF_OAUTH_REDIRECT_URI,
    })
    return data
  }

  static async getAuthorizeUrl() {
    const { data } = await OAuthService.apiClient.get(`/v1/oauth/url`, {
      redirect_uri: config.SF_OAUTH_REDIRECT_URI,
    })
    return data
  }

  static async verifyAccessToken(accessToken) {
    const { status } = await OAuthService.apiClient.post(
      `/v1/oauth/verify_php`,
      undefined,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    )
    return status === "success"
  }

  static async refreshAccess(refreshToken) {
    const { data } = await OAuthService.apiClient.post(`/v1/oauth/refresh`, {
      refreshToken,
      redirect_uri: config.SF_OAUTH_REDIRECT_URI,
    })
    return data
  }
}

export default OAuthService
