import LocalStorageService from "./local_storage_service"

const AUTH = "auth"

class AuthService {
  static saveAuth(data) {
    const existing = LocalStorageService.getItem(AUTH)
    if (!existing) {
      LocalStorageService.setItem(AUTH, data)
      return data
    }
    const extended = {...existing, ...data}
    LocalStorageService.setItem(AUTH, extended)
    return extended
  }

  static getAuth() {
    return LocalStorageService.getItem(AUTH)
  }

  static removeAuth() {
    LocalStorageService.removeItem(AUTH)
  }
}

export default AuthService
