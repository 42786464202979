import { types } from "mobx-state-tree"

const Hi = types
  .model("Hi", {
    version: types.string,
    displayVersion: types.string,
  })
  .views((self) => {
    return {}
  })

export default Hi
