// This script maps the measured audiometric thresholds to the nearest
// representative audiogram (formerly called "Bisgaards").
// 20230509 Eargo. Developed by hearing science team. Coded by Andrew Johnson

export function findBestAudiogramIndex(userAudiogram, audiogramList) {
  // Top function takes a single user audiogram (5 thresholds),
  // and calculates the mean absolute threshold error ("MATE")
  // using each of a long list of representative audiograms.
  let mateScores = []
  let lowestScore = 99

  for (let i = 0; i < audiogramList.length; i++) {
    mateScores[i] = meanAbsThreshError(userAudiogram, audiogramList[i])
    lowestScore = Math.min(lowestScore, mateScores[i])
  }

  let index = mateScores.indexOf(lowestScore)
  return index === -1 ? 0 : audiogramList[index][0]
}

function meanAbsThreshError(userAudiogram, representativeAudiogram) {
  // calculate the mean absolute threshold error between two individual
  // audiograms. They should both be length = 5
  let mate = 0
  const N = userAudiogram.length
  for (let f = 0; f < N; f++) {
    mate =
      mate +
      (1 / N) * Math.abs(userAudiogram[f] - representativeAudiogram[f + 1])
  }
  return mate
}
