import React, { useState } from "react"
import { Grid, MenuItem } from "@material-ui/core"
import { useConfigurationStore, useUiStore } from "hooks/stores"
import { observer } from "mobx-react"
import { SortableHandle } from "react-sortable-hoc"
import {
  StyledPaper,
  StyledMenu,
  StyledSortableHandle,
  StyledEmptyText,
  ControlButtons,
  ActionWrapper,
  HeaderWrapper,
} from "./hearing_profile_tile_styles"

const HearingProfileTileDisabled = ({ isEnabled, index, onChange }) => {
  const { applyForReset } = useConfigurationStore()

  const { showConfigurationModal } = useUiStore()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const setEnabled = (index) => {
    const data = {
      target: {
        name: `isEnabled-${index}`,
        value: index,
      },
    }
    onChange(data)
    handleClose()
  }

  const DragHandle = SortableHandle(() => <StyledSortableHandle />)

  return (
    <Grid item xs={12} md={6} key={index}>
      <StyledPaper>
        <HeaderWrapper>
          {showConfigurationModal && (
            <ControlButtons>
              <ActionWrapper
                id={`choose-menu-hearing-profile-tile-${index}`}
                onClick={handleClick}
              />
              <StyledMenu
                id={`choose-menu-hearing-profile-tile-${index}`}
                anchorEl={anchorEl}
                open={Boolean(
                  anchorEl &&
                    anchorEl.id === `choose-menu-hearing-profile-tile-${index}`
                )}
                onClose={handleClose}
              >
                {!isEnabled && (
                  <MenuItem onClick={() => setEnabled(index)}>
                    Enable program
                  </MenuItem>
                )}
              </StyledMenu>
            </ControlButtons>
          )}
          {showConfigurationModal && !applyForReset && <DragHandle />}
        </HeaderWrapper>
        {!isEnabled && <StyledEmptyText>Profile is disabled</StyledEmptyText>}
      </StyledPaper>
    </Grid>
  )
}

export default observer(HearingProfileTileDisabled)
