import React, { useEffect, useState } from "react"
import { AppBar, Tabs, Tab, Box } from "@material-ui/core"
import styled from "styled-components"
import colors from "styles/colors"
import breakpoint from "styled-components-breakpoint"
import Programs from "./programs/programs"
import Journey from "./journey/journey"
import NewConfigurationModal from "components/modals/new_configuration_modal/new_configuration_modal"
import Statistics from "./statistics/statistics"
import {
  useConfigurationStore,
  useJournalStore,
  useSessionStore,
  useUiStore,
} from "hooks/stores"
import { observer } from "mobx-react"
import Loading from "../loading/loading"
import { DEBUG_ENABLED } from "utils/helpers"

const StyledTabPanel = styled.div`
  justify-content: center;
  background-color: ${colors.lightGrey};
  height: 100%;

  ${breakpoint("mobile", "tablet")`
    overflow-y: auto;
    overflow-x: hidden;
  `};

  ${breakpoint("tablet", "desktop")`
    overflow-y: auto;
    overflow-x: hidden;
  `};
`

const StyledBox = styled(Box)`
  && {
    padding: 0;
  }
`

const StyledTab = styled(Tab)`
  && {
    text-transform: none;
    color: ${colors.blue};
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    max-width: 120px;
    margin-right: 16px;

    &:focus {
      opacity: 1;
    }
  }
`

const StyledTabs = styled(Tabs)`
  && {
    background-color: ${colors.white};

    .MuiTabs-indicator {
      background-color: ${colors.blue};
    }
  }
`

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <StyledTabPanel
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <StyledBox p={3}>{children}</StyledBox>}
    </StyledTabPanel>
  )
}

const MiddleSection = () => {
  const { showConfigurationModal } = useUiStore()
  const { isLoaded: isLoadedConfiguration } = useConfigurationStore()
  const { isLoaded: isLoadedSession } = useSessionStore()
  const { isLoaded: isLoadedJournal } = useJournalStore()

  const [value, setValue] = useState(0)

  useEffect(() => {
    if (showConfigurationModal) {
      setValue(0)
    }
  }, [showConfigurationModal])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  if (!isLoadedConfiguration || !isLoadedSession || !isLoadedJournal) {
    return <Loading fitScreen={true} />
  }

  if (DEBUG_ENABLED) {
    return (
      <>
        <AppBar position="static">
          <StyledTabs value={value} onChange={handleChange} centered>
            <StyledTab label="Programs" />
            <StyledTab label="Statistics" />
            <StyledTab label="Journey" />
          </StyledTabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Programs />
          <NewConfigurationModal />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Statistics />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Journey />
        </TabPanel>
      </>
    )
  }

  return (
    <>
      <AppBar position="static">
        <StyledTabs value={value} onChange={handleChange} centered>
          <StyledTab label="Programs" />
          <StyledTab label="Journey" />
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Programs />
        <NewConfigurationModal />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Journey />
      </TabPanel>
    </>
  )
}

export default observer(MiddleSection)
