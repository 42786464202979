import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react"
import { AuthContext } from "./auth_provider"
import EargoGenerationService from "../services/eargo_generation_service"

export const GenerationsContext = createContext({})
const accountId = new URL(window.location.href).searchParams.get("accountId")

const GenerationsProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [generations, setGenerations] = useState([])
  const [generation, setGeneration] = useState(
    EargoGenerationService.getCurrentGeneration()
  )
  const { apiClient } = useContext(AuthContext)

  const handleSetCurrentGeneration = useCallback((generation) => {
    EargoGenerationService.setCurrentGeneration(generation)
    setGeneration(generation)
  }, [])

  useEffect(() => {
    const getAvailableGenerations = async () => {
      try {
        setLoading(true)
        const response = await apiClient.get(
          `/v1/php/customers/${accountId}/generations`
        )
        if (response.data.generations.length === 0) {
          throw new Error("Customer doesn't have any generations")
        }
        setLoaded(true)
        setLoading(false)
        setGenerations(response.data.generations)
        handleSetCurrentGeneration(response.data.generations[0])
      } catch (e) {
        console.error(e)
        setError(e)
      }
    }

    getAvailableGenerations()
  }, [])

  return (
    <GenerationsContext.Provider
      value={{
        loading,
        loaded,
        error,
        generations,
        generation,
        setGeneration: handleSetCurrentGeneration,
      }}
    >
      {children}
    </GenerationsContext.Provider>
  )
}

export default GenerationsProvider
