import { types, getEnv } from "mobx-state-tree"

const RoutesStore = types
  .model("RoutesStore", {})
  .views((self) => {
    return {
      translatePathname(pathname, locale) {
        return {
          pl: pathname.replace(/^(\/en)/, ""),
          en: `/en${pathname}`,
        }[locale]
      },
      get root() {
        return "/"
      },
      get login() {
        return "/oauth/login"
      },
      get logout() {
        return "/oauth/logout"
      },
      get callback() {
        return "/oauth/callback"
      },
    }
  })
  .actions((self) => {
    const { history } = getEnv(self)
    return {
      redirectToRoot() {
        history.push(self.root)
      },
    }
  })

export default RoutesStore
