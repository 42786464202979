import React from "react"
import { observer } from "mobx-react"
import Navbar from "../../components/navbar/default_navbar/default_navbar"
import Helmet from "react-helmet"
import styled from "styled-components"
import colors from "styles/colors"
import breakpoint from "styled-components-breakpoint"
import MiddleSection from "../../components/middle_section/middle_section"
import Notification from "../../components/notification/notification"
import RightSection from "../../components/right_section/right_section"
import { useUiStore } from "../../hooks/stores"
import LeftSection from "../../components/left_section/left_section"
import EargoGenerationService from "../../services/eargo_generation_service"

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 42px);
`

const MiddleSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`

const DrawerButton = styled.button`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 118px;
  background: #003c7d;
  border: none;
  color: white;
  background: ${colors.blue};
  z-index: 1301;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`

const RightDrawerButton = styled(DrawerButton)`
  right: ${({ openDrawer }) => (openDrawer ? "300px" : "0")};
  transition: right 0.225s;
  border-radius: 24px 0 0 24px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`

const LeftDrawerButton = styled(DrawerButton)`
  left: ${({ openDrawer }) => (openDrawer ? "400px" : "0")};
  transition: left 0.225s;
  border-radius: 0 24px 24px 0;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  ${breakpoint("mobile")`
       left: ${({ openDrawer }) => (openDrawer ? "300px" : "0")};
   `};
`

const HomePage = () => {
  const {
    showLogsDrawer,
    switchLogsDrawer,
    showDevicesDrawer,
    switchDevicesDrawer,
  } = useUiStore()

  const openLogsDrawer = () => {
    switchLogsDrawer()
  }

  const openDevicesDrawer = () => {
    switchDevicesDrawer()
  }

  return (
    <>
      <Helmet>
        <title>PHP Portal</title>
      </Helmet>
      <Navbar />
      <Notification />
      <Wrapper>
        <LeftDrawerButton
          openDrawer={showDevicesDrawer}
          onClick={openDevicesDrawer}
        >
          {!showDevicesDrawer ? `>` : `<`}
        </LeftDrawerButton>
        <LeftSection />
        <MiddleSectionWrapper>
          <MiddleSection />
        </MiddleSectionWrapper>
        <RightDrawerButton openDrawer={showLogsDrawer} onClick={openLogsDrawer}>
          {!showLogsDrawer ? `<` : `>`}
        </RightDrawerButton>
        <RightSection />
      </Wrapper>
    </>
  )
}

export default observer(HomePage)
