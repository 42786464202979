import { DEFAULT_NOISE_REDUCTION_VALUES } from "../utils/helpers"
import EargoGenerationService from "./eargo_generation_service"

export default class HearingProfilesService {
  static getNoiseReductionDefaultValueForOffset(offset) {
    const generation = EargoGenerationService.getCurrentGeneration()
    return DEFAULT_NOISE_REDUCTION_VALUES[generation][offset]
  }

  static getNoiseReductionDefaultValueForPreset(preset) {
    const generation = EargoGenerationService.getCurrentGeneration()
    return DEFAULT_NOISE_REDUCTION_VALUES[generation][preset]
  }
}
