import { flattenDeep, groupBy, isEmpty, sum } from "lodash"

const calculateAverage = (...args) => {
  if (isEmpty(args)) return 0
  const elements = flattenDeep(args)
  return parseFloat((sum(elements) / elements.length).toFixed(2))
}

const calculateActiveUsageHours = (collection) => {
  const activeUsageHoursArray = collection.map((obj) => obj.activeUsageHours)
  const activeUsageMinutesRightArray = collection.map(
    (obj) => obj.rightActiveUsageMinutes
  )
  const activeUsageMinutesLeftArray = collection.map(
    (obj) => obj.leftActiveUsageMinutes
  )

  const calculateTimeSinceLastReset = (side) => {
    if (side === "right") {
      return Math.floor(
        calculateAverage(
          activeUsageMinutesRightArray.map((a) => a?.timeSinceLastReset)
        )
      )
    } else if (side === "left") {
      return Math.floor(
        calculateAverage(
          activeUsageMinutesLeftArray.map((a) => a?.timeSinceLastReset)
        )
      )
    } else {
      return Math.floor(
        calculateAverage(
          activeUsageHoursArray.map((a) => a?.timeSinceLastReset)
        )
      )
    }
  }

  const calculateTimeOutOfCharger = (side) => {
    if (side === "right") {
      return Math.floor(
        calculateAverage(
          activeUsageMinutesRightArray.map((a) => a?.timeOutOfCharger)
        )
      )
    } else if (side === "left") {
      return Math.floor(
        calculateAverage(
          activeUsageMinutesLeftArray.map((a) => a?.timeOutOfCharger)
        )
      )
    } else {
      return Math.floor(
        calculateAverage(activeUsageHoursArray.map((a) => a?.averageHiUsage))
      )
    }
  }

  const leftTimeSinceLastReset = calculateTimeSinceLastReset("left") || 0
  const leftTimeOutOfCharger = calculateTimeOutOfCharger("left") || 0

  const rightTimeSinceLastReset = calculateTimeSinceLastReset("right") || 0
  const rightTimeOutOfCharger = calculateTimeOutOfCharger("right") || 0

  const timeSinceLastReset = calculateTimeSinceLastReset() || 0
  const timeOutOfCharger = calculateTimeOutOfCharger() || 0

  if (
    !leftTimeSinceLastReset ||
    !leftTimeOutOfCharger ||
    !rightTimeSinceLastReset ||
    !rightTimeOutOfCharger
  ) {
    return {
      activeUsageHours: {
        timeSinceLastReset: {
          minutes: timeSinceLastReset % 60,
          hours: (timeSinceLastReset - (timeSinceLastReset % 60)) / 60,
        },
        timeOutOfCharger: {
          minutes: timeOutOfCharger % 60,
          hours: (timeOutOfCharger - (timeOutOfCharger % 60)) / 60,
        },
      },
    }
  } else {
    return {
      leftActiveUsageHours: {
        timeSinceLastReset: {
          minutes: leftTimeSinceLastReset % 60,
          hours: (leftTimeSinceLastReset - (leftTimeSinceLastReset % 60)) / 60,
        },
        timeOutOfCharger: {
          minutes: leftTimeOutOfCharger % 60,
          hours: (leftTimeOutOfCharger - (leftTimeOutOfCharger % 60)) / 60,
        },
      },
      rightActiveUsageHours: {
        timeSinceLastReset: {
          minutes: rightTimeSinceLastReset % 60,
          hours:
            (rightTimeSinceLastReset - (rightTimeSinceLastReset % 60)) / 60,
        },
        timeOutOfCharger: {
          minutes: rightTimeOutOfCharger % 60,
          hours: (rightTimeOutOfCharger - (rightTimeOutOfCharger % 60)) / 60,
        },
      },
    }
  }
}

const calculateAverageVolume = (collection) => {
  const averageVolumeArray = collection.map((obj) => obj.averageVolume).flat()
  const groupedAverageVolume = groupBy(averageVolumeArray, (obj) => obj.name)

  return Object.keys(groupedAverageVolume).map((name) => {
    const data = groupedAverageVolume[name]
    return {
      name: name,
      pvcleft: calculateAverage(data.map((a) => parseInt(a.pvcleft[1]))),
      uvcleft: [
        calculateAverage(data.map((a) => parseInt(a.uvcleft[0]))),
        calculateAverage(data.map((a) => parseInt(a.uvcleft[1]))),
      ],
      pvcright: calculateAverage(data.map((a) => parseInt(a.pvcright[1]))),
      uvcright: [
        calculateAverage(data.map((a) => parseInt(a.uvcright[0]))),
        calculateAverage(data.map((a) => parseInt(a.uvcright[1]))),
      ],
    }
  })
}

const calculateRightUsageChart = (collection) => {
  const groupedRightUsageChart = groupBy(
    collection.map((obj) => obj.rightUsageChart).flat(),
    (obj) => obj.name
  )
  return Object.keys(groupedRightUsageChart).map((name) => {
    return {
      name: name,
      value: calculateAverage(groupedRightUsageChart[name].map((a) => a.value)),
    }
  })
}

const calculateLeftUsageChart = (collection) => {
  const groupedLeftUsageChart = groupBy(
    collection.map((obj) => obj.leftUsageChart).flat(),
    (obj) => obj.name
  )
  return Object.keys(groupedLeftUsageChart).map((name) => {
    return {
      name: name,
      value: calculateAverage(groupedLeftUsageChart[name].map((a) => a.value)),
    }
  })
}
const calculateUsageList = (collection) => {
  const groupedUsageList = groupBy(
    collection.map((obj) => obj.usageList).flat(),
    (obj) => obj.name
  )
  return Object.keys(groupedUsageList).map((name) => {
    const data = groupedUsageList[name]
    return {
      name: name,
      leftUsage: calculateAverage(data.map((a) => a.leftUsage)),
      rightUsage: calculateAverage(data.map((a) => a.rightUsage)),
      averageVolume: calculateAverage(data.map((a) => a.averageVolume)),
    }
  })
}

export {
  calculateActiveUsageHours,
  calculateAverageVolume,
  calculateRightUsageChart,
  calculateLeftUsageChart,
  calculateUsageList,
}
